import { createI18n } from "vue-i18n";

import { getChangelog } from "./changelog";

const storedLocale =
  localStorage.getItem("locale") || navigator.language.split("-")[0];

const i18n = createI18n({
  // legacy
  legacy: false,

  //injection
  globalInjection: true,

  //default locale
  locale: storedLocale,

  //fallback locale
  fallbackLocale: "en",

  //available locales
  messages: {
    en: {
      general: {
        buttonOk: "Ok",
        buttonCancel: "Cancel",
      },
      home: {
        labelLogin: "To login",
        labelDashboard: "To dashboard",
        labelRegister: "To register",
        labelStore: "To store",
      },
      navbarTop: {
        settings: "Settings",
        logout: "Logout",
      },
      sidebar: {
        title: "Menu",
        dashboard: "Dashboard",
        store: "Store",
        payments: "Payments",
        support: "Contact Support",
        settings: "Settings",
        contracts: "Contracts",
        servers: "Servers",
        gameservers: "Gameservers",
        affiliate: "Affiliate Dashboard",
      },
      dashboard: {
        credits: {
          title: "Your Credits",
          current: "Your balance: ",
          add: "Add funds",
        },
        status: {
          title: "MTN Media Group System Status",
          button: "See full status page",
        },
        example: {
          title: "Example",
          content:
            "This is an example component. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
        welcome: {
          title: "Welcome to the new Dashboard (BETA)",
          content:
            "The MTN Media Group Webpanel puts the power in your hands. Effortlessly manage your account balance, payments, and transaction history. Oversee your contracts, ensuring timely payments and easy online cancellations when needed. Explore the MTN store to discover new products, purchase subscriptions, and rent powerful virtual or dedicated servers. Maintain complete control over your servers – deploy, monitor essential metrics, and manage them seamlessly. Dedicated to gameservers? Start, stop, issue commands, and keep a watchful eye on your console – all from one convenient location. We're committed to continuous improvement, so expect new features and an even smoother experience coming your way!",
        },
        servers: {
          title: "Your Servers",
          name: "Name",
          type: "Type",
          ips: "IP Addresses",
          button: "Go to full server list",
        },
        gameservers: {
          title: "Your Gameservers",
          nickname: "Nickname",
          game: "Game",
          ip: "IP-Address",
          button: "Go to full gameserver list",
        },
        changes: {
          title: "Recent Changes",
          content: getChangelog("en"),
        },
      },
      store: {
        mustBeLoggedIn: "You must be logged in to access the store!",
        title: "Store",
        label_all: "All",
        setup_fee: "One time setup fee: {price} €",
        add_to_cart: "Add to cart",
        go_to_cart: "Go to cart",
        go_to_settings: "Go to settings",
        noAddress: "Please enter your address in the settings first!",
        cart_button: "Cart",
        cart: {
          title: "Shopping Cart",
          empty: "Your cart is empty!",
          checkout: "Checkout",
          total_monthly: "Total monthly: {price} €",
          total_now: "Total now incl. setup fee: {price} €",

          price_monthly: "Monthly price: {price} €",
          price_setup: "One time setup fee: {price} €",

          remove: "Remove from cart",
          return: "Return to store",

          waiver: "By completing this purchase, you acknowledge and agree that you are waiving your 14-day right of withdrawal due to the prompt delivery of our services. This waiver is in accordance with consumer protection regulations for digital content and services that are delivered on a short timescale.",
          waiverError: "You must accept the waiver to continue!",
          waiverLink: "https://www.mtnmedia.group/en/cancellation-policy",
          waiverButton: "Read full policy",
        },
        alerts: {
          addedToCart: "Product successfully added to cart!",
          removedFromCart: "Product successfully removed from cart!",
          checkoutConfirm:
            "Please note: The money will be deducted from your balance immediately and there will be no further confirmation. Purchase with obligation to pay?",
          checkoutConfirmTitle: "Confirm Your Purchase",
          checkoutSuccess: "Purchase successful!",
          checkoutError: "Purchase failed!",
          checkoutErrorBalance: "Not enough balance!",
          alpha:
            "The store is currently in alpha and may not always work as expected. If you encounter any problems, please contact our support.",
          beta:
            "The store is currently in beta. If you encounter any problems, please contact our support.",
        },
        prices_incl_vat: "Prices incl. 19% VAT",
        after_purchase:
          "After purchase it might take 48 hours or even longer until your product is available. In most cases it will only take a few hours.",
        delivery: "Usual Product Delivery times:<br>Minecraft Server - automatic install, within minutes after purchase<br>V-Servers and Webhosting - Usually within a few hours<br>Managed Dedicated Servers - usually within 48 hours",
      },
      servers: {
        types: {
          vps: "V-Server",
          dedicated: "Dedicated Server",
          cloud: "Cloud Server",
        },
        title: "Your Servers",
        id: "ID",
        name: "Name",
        nickname: "Nickname",
        ownermail: "Owner Email",
        ownedByMe: "Owned by me",
        type: "Type",
        ips: "IP Addresses",
        actions: "Actions",
        pending: "Server pending",
        canceled: "Server Contract canceled",
        blocked_payment: "Server blocked due to payment overdue",
        deleted_payment: "Server deleted due to payment overdue",
        details: {
          title: "View details",
          id: "ID: ",
          contract: "Contract ID: ",
          ips: "IP Addresses: ",
          cpu: "CPU: ",
          cores: "Cores: ",
          ram: "RAM: ",
          storage: "Storage: ",
          gpu: "GPU: ",
          nic: "NIC: ",
          bandwidth: "Bandwidth: ",
          traffic: "Traffic: ",
          location: "Location: ",
          os: "Operating System: ",
          edit: {
            nickname: {
              button: "Edit Server Nickname",
              error: "Failed to save nickname!",
              success: "Nickname successfully saved!",
              errorempty: "Nickname cannot be empty!",
              errorsame: "Nickname cannot be the same as before!",
            },
          },
          admins: {
            title: "Server Admins",
            info: "Server admins have full access to your server. They can start, stop, and manage your server. They can also see all server details. They cannot add or remove other admins.",
            delete: "Delete Admin",
            email: "Admin: {email}",
            add: "Add Admin",
            addPlaceholder: "Enter email",
            button: "Show Server Admins",
            errorFetch: "Failed to fetch server admins!",
            successRemove: "Admin successfully removed!",
            errorRemove: "Failed to remove admin!",
            errorInvalidEmail: "Invalid email address!",
            successAdd: "Admin successfully added!",
            errorAdd: "Failed to add admin!",
            addInfo: "Please enter the email address of the user you want to add as an admin. They already need to have an account on our platform.",
            confirmRemove: {
              title: "Confirm Admin Removal",
              message: "Are you sure you want to remove this admin from your server?",
            },
          },
          metrics: {
            button: "Load Server Metrics",
            title: "Server Metrics",
            memory: {
              title: "Memory:",
              total: "Total: {total} GB",
              used: "Used: {used} GB",
              usedPercent: "Used (%): {usedPercent} %",
            },
            uptime: "Uptime: ",
            rtt: "RTT from our Backend to your Server: ",
            cpu_threads: "CPU Threads: ",
            cpu_average: "CPU Average: ",
            cpu_utilization: "CPU Utilization: ",
            cpu_thread: "Thread #",
            cpu_usage: "Usage %",
          },
          provider: {
            reset: {
              button: "Load Server Power Actions",
              title: "Server Power Actions",
              status: {
                current: "Current Server Status (using new system): ",
                not_supported: "Not Supported",
                running: "Running",
                stopped: "Stopped",
                error: "Error",
                unknown: "Unknown",
              },
              info: "Please note: Server power actions are currently in early alpha and may not work as expected. If you encounter any problems, please contact our support.",
              reset: {
                soft: {
                  title: "Run Soft Reset",
                  description: "Will send CTRL+ALT+DEL to the server. Shutdown on UNIX systems, no effect on Windows.",
                },
                hard: {
                  title: "Run Hard Reset",
                  description: "Like pressing Reset Button on the server. No graceful shutdown.",
                },
                power: {
                  title: "Press Power Button",
                  description: "Like pressing Power Button on the server. Graceful shutdown if running, else booting up.",
                },
                power_long: {
                  title: "Press Power Button Long",
                  description: "Like pressing Power Button for a long time. Immediate shutdown.",
                },
                wol: {
                  title: "Send Wake-On-LAN",
                  description: "Send Wake-On-LAN packet to the server. Will start the server if supported. (only available after 2 minutes after reset or power off)",
                },
                start: {
                  title: "Start Server",
                  description: "Start the server.",
                },
                stop: {
                  title: "Stop Server",
                  description: "Stop the server. Will kill the instance, data may be lost.",
                },
                restart: {
                  title: "Restart Server",
                  description: "Restart the server.",
                },
                shutdown: {
                  title: "Shutdown Server",
                  description: "Shutdown server. Will send graceful shutdown signal to the server.",
                },
              },
              confirm: {
                title: "Confirm Power Action",
                message: "Are you sure you want to send this power action your server? This action will be executed immediately and cannot be undone!",
              },
              success: "Power action successfully executed!",
              error: "Failed to execute power action!",
              too_many_requests: "Too many requests! Please wait at least 10 minutes between each try.",
              too_many_requests_shutdown: "Too many requests! Please wait at least 10 minutes between each try. If you just shutdown your server, please wait at least 2 minutes before sending a start command.",
              too_many_requests_wol: "Too many requests! Please wait at least 10 minutes between each try. If you jsut shutdown your server, please wait at least 2 minutes before sending a WOL command.",
            },
          },
        },
      },
      gameservers: {
        title: "Your Gameservers",
        status: {
          title: "Status",
          needsrefresh: "Needs Refresh",
          loading: "Loading...",
          online: "Online",
          offline: "Offline",
        },
        alerts: {
          startsuccess: "Server started successfully!",
          starterror: "Failed to start server!",
          stopsuccess: "Server stopped successfully!",
          stoperror: "Failed to stop server!",
          copysuccess: "Copied {text} to clipboard!",
          copyerror: "Failed to copy to clipboard!",
        },
        edit: {
          title: "Edit Gameserver",
          nickname: "Edit Gameserver Nickname",
          savenickname: "Save Nickname",
          success: "Nickname successfully saved!",
          error: "Failed to save nickname!",
          nicknameempty: "Nickname cannot be empty!",
          images: "Version-Manager",
          selectImage: "Select Version to install",
          installImage: "Install Version",
        },
        reinstall: {
          confirm: {
            message: "WARNING: Are you sure you want to reinstall this gameserver? This will delete all data on it and cannot be undone!",
            title: "Confirm Reinstall",
          },
          success: "Gameserver successfully reinstalled!",
          error: "Failed to reinstall gameserver!",
        },
        name: "Name",
        game: "Game",
        ip: "IP-Address",
        isInstalled: "Installation done",
        installYes: "Yes",
        installNo: "No",
        installPending: "Installation pending",
        canceled: "Gameserver Contract canceled",
        ftpUser: "sFTP Username",
        ftpPassword: "sFTP Password",
        actions: "Actions",
        hints: {
          reveal: "Hover to reveal",
          copy: "Click to copy",
        },
        refresh: "Refresh Status",
        start: "Start Server",
        stop: "Stop Server",
        showdetails: "Show Details",
        hidedetails: "Hide Details",
        console: {
          title: "Console",
          commandsuccess: "Command executed successfully!",
          commanderror: "Failed to execute command!",
          submit: "Submit",
          refresh: "Refresh Logs",
          placeholder: "Enter command here...",
          commandempty: "Command cannot be empty!",
        },
        details: {
          title: "Gameserver Details",
        },
      },
      payments: {
        title: "Select Payment Method",
        euroLabel: "Euro incl. 19% VAT",
        stripe: {
          title: "Stripe",
          description:
            "Stripe offers payment by credit card, Klarna, PayPal and more. Choose an amount: ",
          customDescription:
            "Stripe offers payment by credit card, Klarna, PayPal and more. When clicking on the button below, you will be redirected to the Stripe payment page. There you can enter the amount you want to add to your account. Please note that the minimum amount is 10€. This service is currently in beta and may not work as expected. If you encounter any problems, please contact our support.",
          placeholder: "Select an amount",
          payNow: "Pay now",
          alerts: {
            errorProcessing:
              "Error processing payment. Please try again later.",
            errorRetrieving:
              "Error processing payment. Please try again later.",
            errorCreatingSession:
              "Error creating payment session. Please try again later.",
            errorLoadingStripe:
              "Error loading Stripe. Please try again later.",
          },
          custom: {
            title: "Stripe Custom",
            info: "Stripe custom amount is currently in beta and may not work as expected. If you encounter any problems, please contact our support.",
          },
          embed: {
            description: "Stripe offers payment by credit card, GiroPay, PayPal and more. Click on the Pay Now button to proceed, you can then input the amount you want to add in the top left corner.",
            completefirst: "You already started a payment. Please complete it first.",
          },
          loading: "Loading...",
          info: "Using normal Stripe you cannot freely choose the amount you want to add to your account. Please select one of the predefined amounts.",
        },
        banktransfer: {
          title: "Bank Transfer",
          description:
            "Using bank transfer you can add money to your account. This is using the SEPA system, so it is only available for customers in the EU. Please note that it can take up to 5 business days for the money to arrive on your account. Please select an amount: ",
          payNow: "Continue to bank transfer",
          alerts: {
            errorProcessing: "Error creating payment. Please try again later.",
            errorRetrieving: "Error creating payment. Please try again later.",
            success: "Payment created successful.",
          },
          bankDetails: "Transfer the money to the following bank account: ",
          name: "Account holder: ",
          iban: "IBAN: ",
          bic: "BIC/SWITFT: ",
          bank: "Bank: ",
          reference: "Reference: ",
          amount: "Amount: {amount} €",
          info: "Enter decimal number with a dot (.) as separator. Example: 10.50",
        },
        pending: {
          title: "You have pending payments, please complete them!",
          payNow: "Complete now",
          payNowBank: "Show Bank Details",
          cancel: "Cancel",
          info: "If you have already completed these payments and they are still displayed here, please contact our support.",
          date: "Date",
          amount: "Amount",
          actions: "Actions",
          processing: "Payment processing",
          custom: "Custom amount",
        },
        transactions: {
          title: "Last {amount} Transactions",
          description: "Description",
          amount: "Amount",
          date: "Date",
          balanceBefore: "Balance before",
          balanceAfter: "Balance after",
          reference: "Reference",
          history: {
            title: "Transaction History",
            titlelist: "Transactions from {year}",
            button: "Show All Transactions",
          }
        },
        alerts: {
          cancelSuccess: "Payment cancelled successfully.",
          cancelFailed: "Failed to cancel payment. Please try again later.",
          failed: "Payment failed. Please try again later.",
          success: "Payment successful.",
        },
      },
      contracts: {
        title: "Your Contracts",
        status: {
          active: "Active",
          pending: "Pending - We are getting your products ready",
          pendingAutomation: "Pending - Click Button below to install your products",
          pendingCancellation: "Pending Cancellation",
          canceled: "Cancelled",
        },
        cards: {
          name: {
            pending: "{name} - Pending Cancellation",
          },
          automation: {
            start: "Install your products",
            warning: {
              title: "Warning",
              message: "The install process will start immediately and cannot be undone. It may take a few minutes to complete, please do not close this page until the process is finished.",
            },
            success: "Products successfully installed!",
            error: "Failed to install products!",
          },
          description: "Description: ",
          duration: "Your contract duration is {duration} months.",
          prepayment: "You are paying in {prepayment} month intervals.",
          createdAt: "Created at: ",
          renewal: "Renewal: ",
          price: "Total price: ",
          cancellation: {
            title: "Cancellation: ",
            noticePeriod: "Cancellation notice period: {noticePeriod} days",
            button: {
              cancel: "Cancel Contract",
              confirm: "Confirm Cancellation",
              back: "Back",
            },
            info: "Please select a date on which the cancellation takes effect. On this date you will lose access to your services and all data on them will be unrecoverebly deleted!!",
            success: "Cancellation successfully confirmed!",
            error: "An error has occurred!",
            withdraw: {
              success: "Cancellation successfully withdrawn!",
              error: "An error has occurred!",
              button: "Withdraw Cancellation",
            },
            confirmCancel: {
              title: "Confirm Cancellation",
              message: "Are you sure you want to cancel that contract?",
            },
            confirmWithdraw: {
              title: "Confirm Withdrawal",
              message: "Are you sure you want to withdraw the cancellation of that contract?",
            },
          },
          status: "Status: ",
          oneTimeonly: "Payment one time only",
          product: {
            name: "Product: ",
            description: "Description: ",
            price: "Price: ",
          },
          products: "Products: ",
        },
      },
      settings: {
        profile: {
          title: "Profile Settings",
        },
        donationLink: {
          title: "Edit Donation Link",
          label: "Your Donation Link: ",
          description:
            'You can share this link with your friends and family to receive "donations" for your services. The amount paid will be added to your balance.',
          buttonCopy: "Copy to Clipboard",
          buttonCreate: "Create new Link",
          buttonToggle: "Toggle Link active/inactive",
          loading: "Loading...",
          messages: {
            failedToLoad: "Failed to load donation link.",
            failedToCreate: "Failed to create donation link.",
            linkCreated: "Donation link successfully created.",
            failedToUpdate: "Failed to update donation link.",
            linkUpdated: "Donation link successfully updated.",
            copiedToClipboard: "Link copied to clipboard.",
          },
          info: "Donation links are currently in beta and may not work as expected. If you encounter any problems, please contact our support.",
        },
        changeLanguage: {
          title: "Change Language",
        },
        changeBackground: {
          title: "Change Background",
        },
        changePassword: {
          label: "Set your new password:",
          title: "Change Password",
          success: "Password changed successfully!",
          error: "Password change failed!",
          errorEmpty: "Password cannot be empty!",
          errorMatch: "Passwords do not match!",
          errorOtpEmpty: "OTP cannot be empty!",
          submit: "Reset Password",
          labelOldPassword: "Old Password",
          labelNewPassword: "New Password",
          labelNewPasswordRepeat: "Repeat New Password",
          labelOtp: "OTP",
        },
        changeUserData: {
          title: "Change User Data",
          titleAddress: "Change Address",
          labelAddress1: "Address",
          labelCity: "City",
          labelCountry: "Country",
          labelZip: "Zip",
          labelState: "State",
          submit: "Save",
          error: "An error has occurred!",
          success: "Data successfully saved!",
        },
        changeEmail: {
          title: "Change Email",
          labelEmail: "New Email",
          labelPassword: "Password",
          labelOtp: "OTP",
          submit: "Change Email",
          error: "An error has occurred!",
          requestSuccess: "Email change request successfully sent!",
          success: "Email successfully changed!",
        },
        links: {
          title: "Linking / Security Keys",
          discord: {
            title: "Discord",
            linkSuccess: "Discord account linked successfully!",
            linkError: "Discord account link failed!",
            linkDelete: "Delete Link",
            linkStart: "Link Now",
            noLink: "You have not linked your Discord account yet.",
          },
        },
        fido2: {
          title: "Webauthn/FIDO2",
          deleteConfirm: {
            message: "Are you sure you want to delete the key: {displayName}?",
            title: "Delete Key",
          },
          error: "An error has occurred!",
          createLink: "Create Key",
        },
        twoFactor: {
          title: "Two Factor Authentication",
          error: "An error has occurred. Please try again later.",
          errorTitle: "Error",
          errorNoCode: "Please enter a code!",
          successTitle: "Success",
          successEnable: "Two Factor Authentication successfully enabled!",
          successDisable: "Two Factor Authentication successfully disabled!",
          deactivate: "Deactivate",
          twoFactor: "You have enabled Two Factor Authentication.",
          noTwoFactor: "You have not enabled Two Factor Authentication.",
          enterAppCode: "Enter the code displayed in the app:",
          verificationNeeded:
            "You still need to verify your Two Factor Authentication!For security reasons, a new QR code is generated. Delete the old entry from your app and complete the process this time by entering the code that will then be displayed!",
          userUnderstand: "Ok, I understand!",
          activateNow: "Activate now",
          scanQR:
            "Scan the QR code or configure your authenticator app manually:",
          verify: "Verify",
          saveBackupCodes:
            "Your Two Factor Authentication has been successfully activated! Please save the following backup codes, otherwise you will no longer have access to your account if you lose your phone or delete the app!",
          download: "Download",
        },
      },
      authentication: {
        login: {
          title: "Login here: ",
          success: "Login successful!",
          errorNotActivated: "User is not activated",
          errorWrongOtp: "Two Factor Code is wrong",
          errorUserNotFound: "User not found",
          errorWrongPassword: "Password is wrong",
          errorUserBanned: "User is banned",
          error: "An error has occurred",
          labelEmail: "Email",
          labelPassword: "Password",
          labelOtp: "Two Factor Code",
          labelLogin: "Login",
          labelForgotPassword: "Forgot your password? No problem: ",
          labelResetPassword: "Reset Password",
          labelOrSimple: "Or simple:",
          labelDiscord: "Login with Discord",
          labelFIDO2: "Login with FIDO2",
          labelEmailRequired: "Email cannot be empty!",
          labelPasswordRequired: "Password cannot be empty!",
          labelOtpRequired: "Two Factor Code cannot be empty!",
        },
        register: {
          notActivated:
            "Your account is not activated yet. Please activate it first. Click on this message to receive a new activation mail.",
          success:
            "Registration successful! Activate your account via the link in the email.",
          activationMailSent: "Activation mail has been sent.",
          activationMailError: "Activation mail could not be sent.",
          errorNoRegistrationAllowed: "Registration is currently not possible.",
          errorEmailTaken: "This email address is already taken.",
          errorEmailInvalid: "This email address is invalid.",
          error: "An error has occurred.",
          labelCreateAccount: "Create your account now:",
          labelFirstName: "First Name",
          labelLastName: "Last Name",
          labelRepeatPassword: "Repeat Password",
          labelAcceptTerms: "I accept the terms of use.",
          labelAcceptPrivacyText: "I have read and accept the privacy policy:",
          labelPrivacyPolicy: "https://www.mtnmedia.group/en/privacy-policy/",
          labelRegister: "Register",
          labelFirstNameRequired: "First Name cannot be empty!",
          labelLastNameRequired: "Last Name cannot be empty!",
          labelAcceptTermsRequired: "You must accept the terms of use!",
          labelAcceptPrivacyRequired: "You must accept the privacy policy!",
          labelPasswordNotMatch: "Passwords do not match!",
        },
        forgotPassword: {
          enterEmail: "Enter your email address:",
          mailSend: "An email to reset your password has been sent.",
        },
        changePassword: {
          label: "Set your new password:",
          submit: "Reset Password",
          error: "An error has occurred!",
          success: "Password changed successfully!",
          login: "To login",
        },
      },
      affiliate: {
        title: "Affiliate Dashboard",
        card: {
          inviteCode: "Invite Code: {code}",
          url: "URL: {url}",
          createdAt: "Created at: {date}",
          used: "Uses: {uses} / {maxUses}",
        },
        form: {
          maxUses: "Max Uses (number between 1 and 5)",
        },
        actions: {
          createInvite: "Create new Invite",
        },
        errors: {
          enterMaxUses: "Please enter the max uses!",
          inviteCreationFailure: "Failed to create invite!",
        },
        success: {
          inviteCreated: "Invite successfully created!",
        },
      },
      admin: {
        sidebar: {
          title: "Admin Menu",
          userDashboard: "User Dashboard",
          adminDashboard: "Admin Dashboard",
          users: "Users",
          banktransfers: "BankTransfer Payments",
          servers: "Servers",
          gameservers: "Gameservers",
          products: "Products",
        },
        users: {
          title: "Users",
          email: "Email",
          name: "Name",
          balance: "Balance",
          flags: "Flags",
          actions: "Actions",
          details: {
            title: "User Details",
          },
          renew: "Renew Contracts",
        },
        servers: {
          title: "Servers",
        },
        gameservers: {
          title: "Gameservers",
        },
        products: {
          title: "Products",
          id: "ID",
          name: "Name",
          description: "Description",
          price: "Price",
          actions: "Actions",
          form: {
            titlecreate: "Create Product",
            titleedit: "Edit Product",
            labelname: "Name",
            labeldescription: "Description",
            labelprice: "Price",
            submit: "Save",
            success: "Product successfully saved!",
            errorsave: "Failed to save product!",
            errorfetch: "Failed to fetch product!",
          },
        },
        payments: {
          banktransfer: {
            title: "Pending BankTransfer Payments",
            confirm: {
              success: "Payment successfully confirmed!",
              error: "Failed to confirm payment!",
              button: "Confirm Payment",
              message: "Are you sure you want to confirm this payment?",
              title: "Confirm Payment",
              okText: "Yes",
              cancelText: "No",
            },
            createdAt: "Created at",
            amount: "Amount",
            userid: "User ID",
            token: "Token",
            empty: "No pending payments!",
          }
        }
      },
    },
    de: {
      general: {
        buttonOk: "Ok",
        buttonCancel: "Abbrechen",
      },
      home: {
        labelLogin: "Zum Login",
        labelDashboard: "Zum Dashboard",
      },
      navbarTop: {
        settings: "Einstellungen",
        logout: "Abmelden",
      },
      sidebar: {
        title: "Menü",
        dashboard: "Dashboard",
        store: "Store",
        payments: "Zahlungen",
        support: "Support kontaktieren",
        settings: "Einstellungen",
        contracts: "Verträge",
        servers: "Server",
        gameservers: "Gameserver",
        affiliate: "Affiliate Dashboard",
      },
      dashboard: {
        credits: {
          title: "Deine Credits",
          current: "Dein Guthaben:",
          add: "Guthaben aufladen",
        },
        status: {
          title: "MTN Media Group System Status",
          button: "Zur Statusseite",
        },
        example: {
          title: "Beispiel",
          content:
            "Dies ist ein Beispielkomponente. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
        welcome: {
          title: "Willkommen im neuen MTN Webpanel (BETA)",
          content:
            "Das MTN Media Group Webpanel macht alles einfacher. Verwalten Sie Ihren Kontostand, Zahlungen und alle Transaktionen ganz bequem. Behalten Sie Ihre Verträge im Blick und kündigen Sie online, wenn nötig. Entdecken Sie neue Produkte im MTN-Shop und mieten Sie leistungsstarke Server. Steuern Sie Ihre Server komplett selbst – starten, stoppen und überwachen Sie alles an einem zentralen Ort. Wir arbeiten ständig an Verbesserungen – freuen Sie sich auf neue Funktionen!",
        },
        servers: {
          title: "Deine Server",
          name: "Name",
          type: "Typ",
          ips: "IP Addressen",
          button: "Zur vollständigen Serverliste",
        },
        gameservers: {
          title: "Deine Gameserver",
          nickname: "Nickname",
          game: "Spiel",
          ip: "IP-Addresse",
          button: "Zur vollständigen Gameserverliste",
        },
        changes: {
          title: "Letzte Updates",
          content: getChangelog("de"),
        },
      },
      store: {
        mustBeLoggedIn: "Du musst eingeloggt sein, um den Store zu betreten!",
        title: "Store",
        label_all: "Alle",
        setup_fee: "Einmalige Setup-Gebühr: {price} €",
        add_to_cart: "In den Warenkorb",
        go_to_cart: "Zum Warenkorb",
        go_to_settings: "Zu den Einstellungen",
        noAddress: "Bitte gib zuerst deine Adresse in den Einstellungen ein!",
        cart_button: "Warenkorb",
        cart: {
          title: "Warenkorb",
          empty: "Dein Warenkorb ist leer!",
          checkout: "Jetzt kaufen",
          total_monthly: "Gesamtpreis monatlich: {price} €",
          total_now: "Gesamtpreis inkl. Setup-Gebühr: {price} €",

          price_monthly: "Monatspreis: {price} €",
          price_setup: "Einmalige Setup-Gebühr: {price} €",

          remove: "Aus dem Warenkorb entfernen",
          return: "Zurück zum Store",

          waiver: "Ich bestätige, dass ich mein 14-tägiges Widerrufsrecht verliere, da die Dienstleistung sofort beginnt. Diese Verzichtserklärung erfolgt in Übereinstimmung mit den Verbraucherschutzbestimmungen für digitale Inhalte und Dienstleistungen, die kurzfristig bereitgestellt werden.",
          waiverError: "Du musst die Verzichtserklärung akzeptieren, um fortzufahren!",
          waiverLink: "https://www.mtnmedia.group/de/widerrufsbelehrung/",
          waiverButton: "Zur Widerrufsbelehrung",
        },
        alerts: {
          addedToCart: "Produkt erfolgreich in den Warenkorb gelegt!",
          removedFromCart: "Produkt erfolgreich aus dem Warenkorb entfernt!",
          checkoutConfirm:
            "Bitte beachten: Der Betrag wird umgehend von Ihrem Guthaben abgebucht, eine weitere Bestätigung erfolgt nicht. Kauf mit Zahlungspflicht?",
          checkoutConfirmTitle: "Bitte bestätigen Sie Ihren Kauf",
          checkoutSuccess: "Kauf erfolgreich!",
          checkoutError: "Kauf fehlgeschlagen!",
          checkoutErrorBalance: "Nicht genug Guthaben!",
          alpha:
            "Der Store ist aktuell in der Alpha und funktioniert möglicherweise nicht immer wie erwartet. Wenn du auf Probleme stößt, kontaktiere bitte unseren Support.",
          beta: 
            "Der Store ist aktuell in der Beta. Wenn du auf Probleme stößt, kontaktiere bitte unseren Support.",
        },
        prices_incl_vat: "Preise inkl. 19% MwSt.",
        after_purchase:
          "Nach dem Kauf kann es 48 Stunden oder länger dauern, bis dein Produkt verfügbar ist. In den meisten Fällen wird es nur wenige Stunden dauern.",
        delivery: "Übliche Produktlieferzeiten:<br>Minecraft Server - automatische Installation, innerhalb von Minuten nach dem Kauf<br>V-Servers und Webhosting - In der Regel innerhalb weniger Stunden<br>Managed Dedicated Servers - In der Regel innerhalb von 48 Stunden",
      },
      servers: {
        title: "Deine Server",
        name: "Name",
        nickname: "Nickname",
        ownermail: "Owner Email",
        ownedByMe: "Owned by me",
        type: "Typ",
        ips: "IP Addressen",
        actions: "Aktionen",
        pending: "Server ausstehend",
        canceled: "Server-Vertrag abgelaufen",
        blocked_payment: "Server gesperrt wegen Zahlungsverzug",
        deleted_payment: "Server gelöscht wegen Zahlungsverzug",
        details: {
          title: "Details anzeigen",
          ips: "IP Addressen: ",
          cpu: "CPU: ",
          cores: "Kerne: ",
          ram: "RAM: ",
          storage: "Speicher: ",
          gpu: "GPU: ",
          nic: "NIC: ",
          bandwidth: "Bandbreite: ",
          traffic: "Traffic:  ",
          location: "Standort: ",
          os: "Betriebssystem: ",
          edit: {
            nickname: {
              button: "Server-Nickname bearbeiten",
              error: "Nickname konnte nicht gespeichert werden!",
              success: "Nickname erfolgreich gespeichert!",
              errorempty: "Nickname darf nicht leer sein!",
              errorsame: "Der neue Nickname darf nicht gleich dem alten sein!",
            },
          },
          admins: {
            title: "Server-Admins",
            info: "Server-Admins haben vollen Zugriff auf deinen Server. Sie können deinen Server starten, stoppen und verwalten. Sie können auch alle Serverdetails einsehen. Sie können keine anderen Admins hinzufügen oder entfernen.",
            delete: "Admin entfernen",
            add: "Admin hinzufügen",
            addPlaceholder: "Email eingeben",
            button: "Server-Admins anzeigen",
            errorFetch: "Admins konnten nicht geladen werden!",
            successRemove: "Admin erfolgreich entfernt!",
            errorRemove: "Admin konnte nicht entfernt werden!",
            errorInvalidEmail: "Ungültige Email-Adresse!",
            successAdd: "Admin erfolgreich hinzugefügt!",
            errorAdd: "Admin konnte nicht hinzugefügt werden!",
            addInfo: "Bitte gib die Email-Adresse des Benutzers ein, den du als Admin hinzufügen möchtest. Der Benutzer muss bereits ein Konto auf unserer Plattform haben.",
            confirmRemove: {
              title: "Admin entfernen bestätigen",
              message: "Bist du sicher, dass du diesen Admin entfernen möchtest?",
            },
          },
          metrics: {
            button: "Lade Serverauslastung",
            title: "Serverauslastung",
            memory: {
              title: "Arbeitspeicher:",
              total: "Insgesamt: {total} GB",
              used: "In Benutzung: {used} GB",
              usedPercent: "Auslastung (%): {usedPercent} %",
            },
            uptime: "Betriebszeit: ",
            rtt: "RTT von unserem Backend zu deinem Server: ",
            cpu_threads: "CPU Threads:",
            cpu_average: "CPU Durchschnitt: ",
            cpu_utilization: "CPU Auslastung: ",
            cpu_thread: "Thread #",
            cpu_usage: "Auslastung %",
          },
          provider: {
            reset: {
              button: "Lade Server-Power-Aktionen",
              title: "Server-Power-Aktionen",
              status: {
                current: "Aktueller Serverstatus (mit neuem System): ",
                not_supported: "Nicht unterstützt",
                running: "Online",
                stopped: "Offline",
                error: "Fehler",
                unknown: "Unbekannt",
              },
              info: "Bitte beachten: Server-Power-Aktionen sind derzeit in der frühen Alpha und funktionieren möglicherweise nicht wie erwartet. Wenn du auf Probleme stößt, kontaktiere bitte unseren Support.",
              reset: {
                soft: {
                  title: "Soft-Reset ausführen",
                  description: "Sendet CTRL+ALT+DEL an den Server. Herunterfahren auf UNIX-Systemen, keine Auswirkung auf Windows.",
                },
                hard: {
                  title: "Hard-Reset ausführen",
                  description: "Wie das Drücken der Reset-Taste am Server. Kein sauberes Herunterfahren.",
                },
                power: {
                  title: "Drücke die Power-Taste",
                  description: "Wie das Drücken der Power-Taste am Server. Sauberes Herunterfahren bei laufendem Betrieb, sonst Booten.",
                },
                power_long: {
                  title: "Drücke die Power-Taste lange",
                  description: "Wie das Drücken der Power-Taste für eine lange Zeit. Sofortiges Herunterfahren.",
                },
                wol: {
                  title: "Wake-On-LAN senden",
                  description: "Sendet ein Wake-On-LAN-Paket an den Server. Startet den Server, wenn unterstützt. (nur verfügbar 2 Minuten nach Reset oder anderweitigem Ausschalten)",
                },
                start: {
                  title: "Server starten",
                  description: "Startet den Server.",
                },
                stop: {
                  title: "Server stoppen.",
                  description: "Stoppt den Server. Beended die Instanz, Daten können verloren gehen.",
                },
                restart: {
                  title: "Server neu starten",
                  description: "Startet den Server neu.",
                },
                shutdown: {
                  title: "Server herunterfahren.",
                  description: "Server herunterfahren. Sendet einen Shutdown-Befehl an den Server.",
                },
              },
              confirm: {
                title: "Power Aktion bestätigen",
                message: "Bist du sicher, dass du diese Power-Aktion an deinen Server senden möchtest? Diese Aktion wird sofort ausgeführt und kann nicht rückgängig gemacht werden!",
              },
              success: "Power Aktion erfolgreich ausgeführt!",
              error: "Power Aktion konnte nicht ausgeführt werden!",
              too_many_requests: "Zu viele Anfragen! Bitte warte mindestens 10 Minuten zwischen jedem Versuch.",
              too_many_requests_shutdown: "Zu viele Anfragen! Bitte warte mindestens 10 Minuten zwischen jedem Versuch. Wenn du deinen Server gerade heruntergefahren hast, warte bitte mindestens 2 Minuten, bevor du einen Start-Befehl sendest.",
              too_many_requests_wol: "Zu viele Anfragen! Bitte warte mindestens 10 Minuten zwischen jedem Versuch. Wenn du deinen Server gerade zurückgesetzt hast, warte bitte mindestens 2 Minuten, bevor du einen WOL-Befehl sendest.",
            },
          },
        },
      },
      gameservers: {
        title: "Deine Gameserver",
        status: {
          title: "Status",
          needsrefresh: "Muss aktualisiert werden",
          loading: "Lädt...",
          online: "Online",
          offline: "Offline",
        },
        alerts: {
          startsuccess: "Server erfolgreich gestartet!",
          starterror: "Server konnte nicht gestartet werden!",
          stopsuccess: "Server erfolgreich gestoppt!",
          stoperror: "Server konnte nicht gestoppt werden!",
          copysuccess: "{text} wurde in die Zwischenablage kopiert!",
          copyerror: "Konnte nicht in die Zwischenablage kopiert werden!",
        },
        edit: {
          title: "Gameserver bearbeiten",
          nickname: "Gameserver Nickname bearbeiten",
          savenickname: "Nickname speichern",
          success: "Nickname erfolgreich gespeichert!",
          error: "Nickname konnte nicht gespeichert werden!",
          nicknameempty: "Nickname darf nicht leer sein",
          images: "Version-Manager",
          selectImage: "Version zum installieren auswählen",
          installImage: "Version installieren",
        },
        reinstall: {
          confirm: {
            message: "WARNUNG: Bist du sicher, dass du diesen Gameserver neu installieren möchtest? Dies löscht alle Daten darauf und kann nicht rückgängig gemacht werden!",
            title: "Neuinstallation bestätigen",
          },
          success: "Gameserver erfolgreich neu installiert!",
          error: "Gameserver konnte nicht neu installiert werden!",
        },
        name: "Nickname",
        game: "Spiel",
        ip: "IP-Addresse",
        isInstalled: "Installation abgeschlossen",
        installYes: "Ja",
        installNo: "Nein",
        installPending: "Installation ausstehend",
        canceled: "Gameserver-Vertrag abgelaufen",
        ftpUser: "sFTP Benutzername",
        ftpPassword: "sFTP Passwort",
        actions: "Aktionen",
        hints: {
          reveal: "Zum Anzeigen hovern",
          copy: "Zum Kopieren klicken",
        },
        refresh: "Status aktualisieren",
        start: "Server starten",
        stop: "Server stoppen",
        showdetails: "Show Details",
        hidedetails: "Hide Details",
        console: {
          title: "Konsole",
          commandsuccess: "Befehl erfolgreich ausgeführt!",
          commanderror: "Befehl konnte nicht ausgeführt werden!",
          submit: "Absenden",
          refresh: "Logs aktualisieren",
          placeholder: "Befehl hier eingeben...",
          commandempty: "Befehl darf nicht leer sein!",
        },
      },
      payments: {
        title: "Zahlungsmethode auswählen",
        euroLabel: "Euro inkl. 19% MwSt.",
        stripe: {
          title: "Stripe",
          description:
            "Stripe bietet Zahlung per Kreditkarte, Klarna, PayPal und mehr. Wähle einen Aufladebetrag: ",
          customDescription:
            "Stripe bietet Zahlung per Kreditkarte, Klarna, PayPal und mehr. Wenn du auf den Button unten klickst, wirst du zur Stripe Zahlungsseite weitergeleitet. Dort kannst du den Betrag eingeben, den du deinem Guthaben hinzufügen möchtest. Bitte beachte, dass der Mindestbetrag 10€ beträgt. Dieser Service ist aktuell in der Beta und funktioniert möglicherweise nicht wie erwartet. Wenn du auf Probleme stößt, kontaktiere bitte unseren Support.",
          placeholder: "Betrag auswählen",
          payNow: "Jetzt bezahlen",
          alerts: {
            errorProcessing:
              "Bei der Zahlung ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
            errorRetrieving:
              "Bei der Zahlung ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
            errorCreatingSession:
              "Error creating payment session. Please try again later.",
            errorLoadingStripe:
              "Error loading Stripe. Please try again later.",
          },
          custom: {
            title: "Stripe Custom",
            info: "Stripe custom ist aktuell in der Beta und funktioniert möglicherweise nicht wie erwartet. Wenn du auf Probleme stößt, kontaktiere bitte unseren Support.",
          },
          embed: {
            description: "Stripe offers payment by credit card, GiroPay, PayPal and more. Klicke auf den Jetzt bezahlen Button, um fortzufahren, du kannst dann den Betrag, den du hinzufügen möchtest, im oberen linken Eck eingeben.",
            completefirst: "You already started a payment. Please complete it first.",
          },
          loading: "Loading...",
          info: "Bei Stripe kannst du nicht frei den Betrag auswählen, den du deinem Guthaben hinzufügen möchtest. Bitte wähle einen der vorgegebenen Beträge aus.",
        },
        banktransfer: {
          title: "Banküberweisung",
          description:
            "Mit Banküberweisung kannst du Geld auf dein Guthaben einzahlen. Dies geschieht über das SEPA System, daher ist es nur für Kunden in der EU verfügbar. Bitte beachte, dass es bis zu 5 Werktage dauern kann, bis das Geld auf deinem Konto eingegangen ist. Bitte wähle einen Betrag aus:",
          payNow: "Zur Banküberweisung",
          alerts: {
            errorProcessing:
              "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
            errorRetrieving:
              "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
            success: "Zahlung erfolgreich erstellt.",
          },
          bankDetails: "Überweise das Geld auf folgendes Bankkonto: ",
          name: "Kontoinhaber: ",
          iban: "IBAN: ",
          bic: "BIC: ",
          bank: "Bank: ",
          reference: "Verwendungszweck: ",
          amount: "Betrag: {amount} €",
          info: "Gib eine Dezimalzahl mit einem Punkt (.) als Trennzeichen ein. Beispiel: 10.50",
        },
        pending: {
          title: "Du hast noch ausstehende Zahlungen, bitte schließe diese ab!",
          payNow: "Jetzt abschließen",
          payNowBank: "Bankdaten anzeigen",
          cancel: "Abbrechen",
          info: "Wenn du diese Zahlungen bereits abgeschlossen hast und sie hier trotzdem noch angezeigt werden, kontaktiere bitte unseren Support.",
          date: "Datum",
          amount: "Betrag",
          actions: "Aktionen",
        },
        transactions: {
          title: "Letzte {amount} Transaktionen",
          description: "Beschreibung",
          amount: "Betrag",
          date: "Datum",
          balanceBefore: "Guthaben vorher",
          balanceAfter: "Guthaben nachher",
          history: {
            title: "Transaktionshistorie",
            titlelist: "Transaktionen aus dem Jahr {year}",
            button: "Alle Transaktionen anzeigen",
          }
        },
        alerts: {
          cancelSuccess: "Zahlung erfolgreich abgebrochen.",
          cancelFailed:
            "Zahlung konnte nicht abgebrochen werden. Bitte versuche es später erneut.",
        },
      },
      contracts: {
        title: "Deine Verträge",
        status: {
          active: "Aktiv",
          pending: "Kündigung ausstehend",
          canceled: "Gekündigt",
          pendingAutomation: "Ausstehend - Klicke auf den Button unten, um deine Produkte zu installieren",
          pendingCancellation: "Kündigung ausstehend",
        },
        cards: {
          name: {
            pending: "{name} - Kündigung ausstehend",
          },
          automation: {
            start: "Produkte installieren",
            warning: {
              title: "Produkte installieren",
              message: "Der Installationsprozess wird sofort gestartet und kann nicht rückgängig gemacht werden. Es kann einige Minuten dauern, bis der Prozess abgeschlossen ist, bitte schließe diese Seite nicht, bis der Prozess abgeschlossen ist.",
            },
            success: "Produkte erfolgreich installiert!",
            error: "Produkte konnten nicht installiert werden!",
          },
          description: "Beschreibung:",
          duration: "Deine Vertragslaufzeit beträgt {duration} Monate.",
          prepayment: "Du zahlst in {prepayment} Monatsintervallen.",
          createdAt: "Erstellt am: ",
          renewal: "Verlängerung: ",
          price: "Gesamtpreis: ",
          cancellation: {
            title: "Kündigung: ",
            noticePeriod: "Kündigungsfrist: {noticePeriod} Tage",
            button: {
              cancel: "Vertrag kündigen",
              confirm: "Kündigung bestätigen",
              back: "Zurück",
            },
            info: "Bitte wähle ein Datum aus, an dem die Kündigung wirksam werden soll. An diesem Datum verlierst du den Zugriff auf deine Dienste und alle Daten darauf werden unwiederbringlich gelöscht!!",
            success: "Kündigung erfolgreich bestätigt!",
            error: "Ein Fehler ist aufgetreten!",
            withdraw: {
              success: "Kündigung erfolgreich zurückgezogen!",
              error: "Ein Fehler ist aufgetreten!",
              button: "Kündigung zurückziehen",
            },
            confirmCancel: {
              title: "Kündigung bestätigen",
              message: "Bist du sicher, dass du deinen Vertrag kündigen möchtest? An diesem Datum verlierst du den Zugriff auf deine Dienste und alle Daten darauf werden unwiederbringlich gelöscht!",
            },
            confirmWithdraw: {
              title: "Kündigung zurückziehen bestätigen",
              message: "Bist du sicher, dass du die Kündigung deines Vertrages zurückziehen möchtest?",
            },
          },
          status: "Status: ",
          oneTimeonly: "Zahlung einmalig",
          product: {
            name: "Produkt: ",
            description: "Beschreibung: ",
            price: "Preis: ",
          },
          products: "Produkte: ",
        },
      },
      settings: {
        profile: {
          title: "Profil Einstellungen",
        },
        donationLink: {
          title: "Spendenlink bearbeiten",
          label: "Dein Spendenlink: ",
          description:
            'Du kannst diesen Link mit deinen Freunden und deiner Familie teilen, um "Spenden" für deine Dienste zu erhalten. Der bezahlte Betrag wird deinem Guthaben gutgeschrieben.',
          buttonCopy: "In Zwischenablage kopieren",
          buttonCreate: "Neuen Link erstellen",
          buttonToggle: "Link aktiv/inaktiv schalten",
          loading: "Lade...",
          messages: {
            failedToLoad: "Spendenlink konnte nicht geladen werden.",
            failedToCreate: "Spendenlink konnte nicht erstellt werden.",
            linkCreated: "Spendenlink erfolgreich erstellt.",
            failedToUpdate: "Spendenlink konnte nicht aktualisiert werden.",
            linkUpdated: "Spendenlink erfolgreich aktualisiert.",
            copiedToClipboard: "Link in Zwischenablage kopiert.",
          },
          info: "Spendenlinks sind aktuell in der Beta und funktionieren möglicherweise nicht wie erwartet. Wenn du auf Probleme stößt, kontaktiere bitte unseren Support.",
        },
        changeLanguage: {
          title: "Sprache ändern",
        },
        changeBackground: {
          title: "Hintergrund ändern",
        },
        changePassword: {
          label: "Setze dein neues Passwort:",
          title: "Passwort ändern",
          success: "Passwort erfolgreich geändert!",
          error: "Passwort ändern fehlgeschlagen!",
          errorEmpty: "Passwort darf nicht leer sein!",
          errorMatch: "Passwörter stimmen nicht überein!",
          errorOtpEmpty: "OTP darf nicht leer sein!",
          submit: "Passwort zurücksetzen",
          labelOldPassword: "Altes Passwort",
          labelNewPassword: "Neues Passwort",
          labelNewPasswordRepeat: "Neues Passwort wiederholen",
          labelOtp: "Zwei-Faktor-Authentifizierungscode",
        },
        changeUserData: {
          title: "Benutzerdaten ändern",
          titleAddress: "Adresse ändern",
          labelAddress1: "Adresse",
          labelCity: "Stadt",
          labelCountry: "Land",
          labelZip: "PLZ",
          labelState: "Bundesland",
          submit: "Speichern",
          error: "Ein Fehler ist aufgetreten!",
          success: "Daten erfolgreich gespeichert!",
        },
        changeEmail: {
          title: "E-Mail-Adresse ändern",
          labelEmail: "Neue E-Mail-Adresse",
          labelPassword: "Passwort",
          labelOtp: "Zwei-Faktor-Authentifizierungscode",
          submit: "E-Mail-Adresse ändern",
          error: "Ein Fehler ist aufgetreten!",
          requestSuccess: "E-Mail-Änderungsanfrage erfolgreich versendet!",
          success: "E-Mail-Adresse erfolgreich geändert!",
        },
        links: {
          title: "Verknüpfungen / Sicherheitsschlüssel",
          discord: {
            title: "Discord",
            linkSuccess: "Discord Konto erfolgreich verknüpft!",
            linkError: "Discord Konto verknüpfen fehlgeschlagen!",
            linkDelete: "Verknüpfung löschen",
            linkStart: "Jetzt verknüpfen",
            noLink: "Du hast deinen Discord-Account noch nicht verknüpft.",
          },
        },
        fido2: {
          title: "Webauthn/FIDO2",
          deleteConfirm: {
            title: "Sicherheitschlüssel löschen",
            message: "Bist du sicher, dass du diesen Sicherheitschlüssel löschen möchtest?",
          },
          error: "Ein Fehler ist aufgetreten!",
          createLink: "Schlüssel erstellen",
        },
        twoFactor: {
          title: "Zwei-Faktor-Authentifizierung",
          error:
            "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
          errorTitle: "Fehler",
          errorNoCode: "Bitte gebe einen Code ein!",
          successTitle: "Erfolgreich",
          successEnable: "Zwei-Faktor-Authentifizierung erfolgreich aktiviert!",
          successDisable:
            "Zwei-Faktor-Authentifizierung erfolgreich deaktiviert!",
          deactivate: "Deaktivieren",
          twoFactor: "Du hast die Zwei-Faktor-Authentifizierung aktiviert.",
          noTwoFactor:
            "Du hast die Zwei-Faktor-Authentifizierung nicht aktiviert.",
          enterAppCode: "Gib den Code ein, der dir in der App angezeigt wird:",
          verificationNeeded:
            "Du musst deine Zwei-Faktor-Authentifizierung noch verifizieren!Aus Sicherheitsgründen wird ein neuer QR-Code generiert. Lösche den alten Eintrag aus deiner App und schließe diesmal den Prozess durch Eingabe des dann angezeigten Codes vollständig ab!",
          userUnderstand: "Ok, ich habe verstanden!",
          activateNow: "Jetzt aktivieren",
          scanQR:
            "Scanne den QR-Code oder konfiguriere deine Authenticator-App manuell:",
          verify: "Verifizieren",
          saveBackupCodes:
            "Deine Zwei-Faktor-Authentifizierung wurde erfolgreich aktiviert! Bitte speichere dir die folgenden Backup-Codes ab, da du sonst keinen Zugriff mehr auf deinen Account hast, wenn du dein Handy verlierst oder die App löschst!",
          download: "Herunterladen",
        },
      },
      authentication: {
        login: {
          title: "Login here: ",
          success: "Login erfolgreich!",
          errorNotActivated: "Benutzer ist nicht aktiviert",
          errorWrongOtp: "Zwei-Faktor-Code ist falsch",
          errorUserNotFound: "Benutzer nicht gefunden",
          errorWrongPassword: "Passwort ist falsch",
          errorUserBanned: "Benutzer ist gebannt",
          error: "Ein Fehler ist aufgetreten",
          labelEmail: "E-Mail-Adresse",
          labelPassword: "Passwort",
          labelOtp: "Zwei-Faktor-Code",
          labelLogin: "Anmelden",
          labelForgotPassword: "Passwort vergessen? Kein Problem: ",
          labelResetPassword: "Passwort zurücksetzen",
          labelOrSimple: "Oder einfacher:",
          labelDiscord: "Mit Discord anmelden",
          labelFIDO2: "Mit FIDO2 anmelden",
          labelEmailRequired: "E-Mail-Adresse darf nicht leer sein!",
          labelPasswordRequired: "Passwort darf nicht leer sein!",
          labelOtpRequired: "Zwei-Faktor-Code darf nicht leer sein!",
        }, 
        register: {
          notActivated:
            "Dein Account ist noch nicht aktiviert. Bitte aktiviere ihn zuerst. Klicke auf diese Meldung, um eine neue Aktivierungsmail zu erhalten.",
          success:
            "Registrierung erfolgreich! Aktiviere deinen Account über den Link in der E-Mail.",
          activationMailSent: "Aktivierungsmail wurde versendet.",
          activationMailError:
            "Aktivierungsmail konnte nicht versendet werden.",
          errorNoRegistrationAllowed:
            "Aktuell ist keine Registrierung möglich.",
          errorEmailTaken: "Diese E-Mail-Adresse ist bereits vergeben.",
          errorEmailInvalid: "Diese E-Mail-Adresse ist ungültig.",
          error: "Ein Fehler ist aufgetreten.",
          labelCreateAccount: "Erstelle jetzt deinen Account:",
          labelFirstName: "Vorname",
          labelLastName: "Nachname",
          labelRepeatPassword: "Passwort wiederholen",
          labelAcceptTerms: "Ich akzeptiere die Nutzungsbedingungen.",
          labelAcceptPrivacyText:
            "Ich habe die Datenschutzerklärung gelesen und akzeptiere sie:",
          labelPrivacyPolicy: "https://www.mtnmedia.group/de/datenschutz",
          labelRegister: "Registrieren",
          labelFirstNameRequired: "Vorname darf nicht leer sein!",
          labelLastNameRequired: "Nachname darf nicht leer sein!",
          labelAcceptTermsRequired:
            "Du musst die Nutzungsbedingungen akzeptieren!",
          labelAcceptPrivacyRequired:
            "Du musst die Datenschutzerklärung akzeptieren!",
          labelPasswordNotMatch: "Passwörter stimmen nicht überein!",
        },
        forgotPassword: {
          enterEmail: "Gib deine E-Mail-Adresse ein:",
          mailSend:
            "Eine E-Mail zum Zurücksetzen deines Passworts wurde versendet.",
        },
        changePassword: {
          label: "Setze dein neues Passwort:",
          submit: "Passwort zurücksetzen",
          error: "Passwort zurücksetzen fehlgeschlagen!",
          success: "Passwort erfolgreich zurückgesetzt!",
          login: "Zum Login",
        },
      },
      affiliate: {
        title: "Affiliate Dashboard",
        card: {
          inviteCode: "Einladungscode: {code}",
          createdAt: "Erstellt am: {date}",
          used: "Benutzt: {uses} / {maxUses}",
        },
        form: {
          maxUses: "Maximale Benutzungen (Zahl zwischen 1 und 5)",
        },
        actions: {
          createInvite: "Neue Einladung erstellen",
        },
        errors: {
          enterMaxUses: "Bitte gebe die maximale Benutzungen an!",
          inviteCreationFailure: "Einladung konnte nicht erstellt werden!",
        },
        success: {
          inviteCreated: "Einladung erfolgreich erstellt!",
        },
      },
      admin: {
        sidebar: {
          title: "Admin Menü",
          userDashboard: "Benutzer Dashboard",
          adminDashboard: "Admin Dashboard",
          users: "Benutzer",
          banktransfers: "Ausstehende Banküberweisungen",
          servers: "Server",
          gameservers: "Gameserver",
        },
        users: {
          title: "Benutzer",
          email: "Email",
          name: "Name",
          balance: "Guthaben",
          flags: "Flags",
          actions: "Aktionen",
          details: {
            title: "Benutzer Details",
          },
          renew: "Verträge verlängern",
        },
        servers: {
          title: "Server",
        },
        gameservers: {
          title: "Gameserver",
        },
        payments: {
          banktransfer: {
            title: "Ausstehende Banküberweisungen",
            confirm: {
              success: "Zahlung erfolgreich bestätigt!",
              error: "Zahlung konnte nicht bestätigt werden!",
              button: "Zahlung bestätigen",
              message: "Bist du sicher, dass du diese Zahlung bestätigen möchtest?",
              title: "Zahlung bestätigen",
              okText: "Ja",
              cancelText: "Abbrechen",
            },
            createdAt: "Erstellt am",
            amount: "Betrag",
            userid: "User ID",
            token: "Token",
            empty: "Keine ausstehenden Zahlungen!",
          }
        }
      },
    },
  },
});

export default i18n;
